import React from "react";
import "./contactus.css";
import { FaWhatsapp } from "react-icons/fa";
function ContactUs() {
  return (
    <div className="contact-main-block">
      <h2 className="contact-heading">Contact </h2>
      <p className="contact-paragraph">
        +91 9637174428 / myfabcollection.atomicloops.app
        <br /> <br />
        Pune,India
      </p>
      <div className="connect-us-btn">
        <a className="whtslink" href="https://wa.me/919637174428">
          <p className="contact-btn-text">
            <FaWhatsapp className="whatsapp-icon" />
            Contact Us
          </p>
        </a>
      </div>
      {/* <div className="contact-form">
        <div className="left-side-form">
          <p className="form-labels">First Name*</p>
          <input className="form-inputs" />
          <p className="form-labels">Last Name*</p>
          <input className="form-inputs" />
          <p className="form-labels">Email*</p>
          <input className="form-inputs" />
          <p className="form-labels">Subject*</p>
          <input className="form-inputs" />
        </div>
        <div className="right-side-form">
          <p className="form-labels">Message*</p>
          <textarea className="form-textarea" />
          <button className="contact-submit-btn">Submit</button>
        </div>
      </div> */}
    </div>
  );
}

export default ContactUs;
