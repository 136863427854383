import React from "react";
import "./aboutus.css";

const AboutUs = () => {
  return (
    <div className="aboutus-main-block">
      <h1 className="abotus-heading">about</h1>
      <p className="aboutus-paragraph">
        My fab Collection is here with exquisite drapes for today's Indian
        women. We have over a hundred different styles, patterns and designs
        which sit perfectly with each occasion at all places. The material is
        butter soft and have a smooth finish. We believe in the power of modern
        India and that is precisely what we have brought for you.
      </p>
      <div className="aboutus-icons-block">
        <div className="icons-div">
          <img src="assets/badge.png" alt="" className="about-icon" />
          <p className="icon-name">Best Quality</p>
        </div>
        <div className="icons-div">
          <img src="assets/feather.png" alt="" className="about-icon" />
          <p className="icon-name">Soft Finish</p>
        </div>
        <div className="icons-div">
          <img src="assets/sustainable.png" alt="" className="about-icon" />
          <p className="icon-name">100% Organic fabric</p>
        </div>
        <div className="icons-div">
          <img src="assets/washing-machine.png" alt="" className="about-icon" />
          <p className="icon-name">Washable</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
