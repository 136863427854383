import React, { useState } from "react";
import "./products.css";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img1.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal1(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img2.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img3.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal3(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img4.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal4(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img5.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal5(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img6.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal6(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="modal-row">
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img7.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal7(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img8.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal8(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img src="assets/shop-img9.jpeg" alt="" className="modal-imges" />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal9(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img10.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal10(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img11.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal11(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img13.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal12(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img12.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal13(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img14.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal14(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img15.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal15(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img16.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal16(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img17.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal17(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img18.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal18(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img19.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal19(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img20.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal20(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img21.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal21(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img22.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal22(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img23.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal23(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img24.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal24(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img25.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal25(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img30.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal26(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img27.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal27(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img31.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal28(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img32.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal29(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img33.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal30(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img34.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal31(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img35.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal32(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <img
                src="assets/shop-img36.jpeg"
                alt=""
                className="modal-imges"
              />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                I'm a product detail. I'm a great place to add more information
                about your product such as sizing, material, care and cleaning
                instructions. This is also a great space to write what makes
                this product special and how your customers can benefit from
                this item.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
// function MyVerticallyCenteredModal33(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img
//                 src="assets/shop-img37.jpeg"
//                 alt=""
//                 className="modal-imges"
//               />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Jasmine Soap
//               </div>
//               <div className="product-info">Cost: ₹ 245 per</div>
//               <div className="product-info">Weight: 100gm</div>
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 I'm a product detail. I'm a great place to add more information
//                 about your product such as sizing, material, care and cleaning
//                 instructions. This is also a great space to write what makes
//                 this product special and how your customers can benefit from
//                 this item.
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
// function MyVerticallyCenteredModal34(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img
//                 src="assets/shop-img38.jpeg"
//                 alt=""
//                 className="modal-imges"
//               />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Jasmine Soap
//               </div>
//               <div className="product-info">Cost: ₹ 245 per</div>
//               <div className="product-info">Weight: 100gm</div>
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 I'm a product detail. I'm a great place to add more information
//                 about your product such as sizing, material, care and cleaning
//                 instructions. This is also a great space to write what makes
//                 this product special and how your customers can benefit from
//                 this item.
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
// function MyVerticallyCenteredModal35(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img
//                 src="assets/shop-img29.jpeg"
//                 alt=""
//                 className="modal-imges"
//               />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Jasmine Soap
//               </div>
//               <div className="product-info">Cost: ₹ 245 per</div>
//               <div className="product-info">Weight: 100gm</div>
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 I'm a product detail. I'm a great place to add more information
//                 about your product such as sizing, material, care and cleaning
//                 instructions. This is also a great space to write what makes
//                 this product special and how your customers can benefit from
//                 this item.
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
// function MyVerticallyCenteredModal36(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img
//                 src="assets/shop-img29.jpeg"
//                 alt=""
//                 className="modal-imges"
//               />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Jasmine Soap
//               </div>
//               <div className="product-info">Cost: ₹ 245 per</div>
//               <div className="product-info">Weight: 100gm</div>
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 I'm a product detail. I'm a great place to add more information
//                 about your product such as sizing, material, care and cleaning
//                 instructions. This is also a great space to write what makes
//                 this product special and how your customers can benefit from
//                 this item.
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
// function MyVerticallyCenteredModal37(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img
//                 src="assets/shop-img29.jpeg"
//                 alt=""
//                 className="modal-imges"
//               />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Jasmine Soap
//               </div>
//               <div className="product-info">Cost: ₹ 245 per</div>
//               <div className="product-info">Weight: 100gm</div>
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 I'm a product detail. I'm a great place to add more information
//                 about your product such as sizing, material, care and cleaning
//                 instructions. This is also a great space to write what makes
//                 this product special and how your customers can benefit from
//                 this item.
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
// function MyVerticallyCenteredModal38(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img
//                 src="assets/shop-img29.jpeg"
//                 alt=""
//                 className="modal-imges"
//               />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Jasmine Soap
//               </div>
//               <div className="product-info">Cost: ₹ 245 per</div>
//               <div className="product-info">Weight: 100gm</div>
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 I'm a product detail. I'm a great place to add more information
//                 about your product such as sizing, material, care and cleaning
//                 instructions. This is also a great space to write what makes
//                 this product special and how your customers can benefit from
//                 this item.
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
// function MyVerticallyCenteredModal39(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img
//                 src="assets/shop-img29.jpeg"
//                 alt=""
//                 className="modal-imges"
//               />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Jasmine Soap
//               </div>
//               <div className="product-info">Cost: ₹ 245 per</div>
//               <div className="product-info">Weight: 100gm</div>
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 I'm a product detail. I'm a great place to add more information
//                 about your product such as sizing, material, care and cleaning
//                 instructions. This is also a great space to write what makes
//                 this product special and how your customers can benefit from
//                 this item.
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
// function MyVerticallyCenteredModal40(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img
//                 src="assets/shop-img29.jpeg"
//                 alt=""
//                 className="modal-imges"
//               />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Jasmine Soap
//               </div>
//               <div className="product-info">Cost: ₹ 245 per</div>
//               <div className="product-info">Weight: 100gm</div>
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 I'm a product detail. I'm a great place to add more information
//                 about your product such as sizing, material, care and cleaning
//                 instructions. This is also a great space to write what makes
//                 this product special and how your customers can benefit from
//                 this item.
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
function Products() {
  const [isHover, setIsHover] = useState(false);
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);
  const [isHover5, setIsHover5] = useState(false);
  const [isHover6, setIsHover6] = useState(false);
  const [isHover7, setIsHover7] = useState(false);
  const [isHover8, setIsHover8] = useState(false);
  const [isHover9, setIsHover9] = useState(false);
  const [isHover10, setIsHover10] = useState(false);
  const [isHover11, setIsHover11] = useState(false);
  const [isHover12, setIsHover12] = useState(false);
  const [isHover13, setIsHover13] = useState(false);
  const [isHover14, setIsHover14] = useState(false);
  const [isHover15, setIsHover15] = useState(false);
  const [isHover16, setIsHover16] = useState(false);
  const [isHover17, setIsHover17] = useState(false);
  const [isHover18, setIsHover18] = useState(false);
  const [isHover19, setIsHover19] = useState(false);
  const [isHover20, setIsHover20] = useState(false);
  const [isHover21, setIsHover21] = useState(false);
  const [isHover22, setIsHover22] = useState(false);
  const [isHover23, setIsHover23] = useState(false);
  const [isHover24, setIsHover24] = useState(false);
  const [isHover25, setIsHover25] = useState(false);
  const [isHover26, setIsHover26] = useState(false);
  const [isHover27, setIsHover27] = useState(false);
  const [isHover28, setIsHover28] = useState(false);
  const [isHover29, setIsHover29] = useState(false);
  const [isHover30, setIsHover30] = useState(false);
  const [isHover31, setIsHover31] = useState(false);
  const [isHover32, setIsHover32] = useState(false);
  // const [isHover33, setIsHover33] = useState(false);
  // const [isHover34, setIsHover34] = useState(false);
  // const [isHover35, setIsHover35] = useState(false);
  // const [isHover36, setIsHover36] = useState(false);
  // const [isHover37, setIsHover37] = useState(false);
  // const [isHover38, setIsHover38] = useState(false);
  // const [isHover39, setIsHover39] = useState(false);
  // const [isHover40, setIsHover40] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow5, setModalShow5] = useState(false);
  const [modalShow6, setModalShow6] = useState(false);
  const [modalShow7, setModalShow7] = useState(false);
  const [modalShow8, setModalShow8] = useState(false);
  const [modalShow9, setModalShow9] = useState(false);
  const [modalShow10, setModalShow10] = useState(false);
  const [modalShow11, setModalShow11] = useState(false);
  const [modalShow12, setModalShow12] = useState(false);
  const [modalShow13, setModalShow13] = useState(false);
  const [modalShow14, setModalShow14] = useState(false);
  const [modalShow15, setModalShow15] = useState(false);
  const [modalShow16, setModalShow16] = useState(false);
  const [modalShow17, setModalShow17] = useState(false);
  const [modalShow18, setModalShow18] = useState(false);
  const [modalShow19, setModalShow19] = useState(false);
  const [modalShow20, setModalShow20] = useState(false);
  const [modalShow21, setModalShow21] = useState(false);
  const [modalShow22, setModalShow22] = useState(false);
  const [modalShow23, setModalShow23] = useState(false);
  const [modalShow24, setModalShow24] = useState(false);
  const [modalShow25, setModalShow25] = useState(false);
  const [modalShow26, setModalShow26] = useState(false);
  const [modalShow27, setModalShow27] = useState(false);
  const [modalShow28, setModalShow28] = useState(false);
  const [modalShow29, setModalShow29] = useState(false);
  const [modalShow30, setModalShow30] = useState(false);
  const [modalShow31, setModalShow31] = useState(false);
  const [modalShow32, setModalShow32] = useState(false);
  // const [modalShow33, setModalShow33] = useState(false);
  // const [modalShow34, setModalShow34] = useState(false);
  // const [modalShow35, setModalShow35] = useState(false);
  // const [modalShow36, setModalShow36] = useState(false);
  // const [modalShow37, setModalShow37] = useState(false);
  // const [modalShow38, setModalShow38] = useState(false);
  // const [modalShow39, setModalShow39] = useState(false);
  // const [modalShow40, setModalShow40] = useState(false);
  const handleMouseOver = () => {
    setIsHover(true);
  };
  const handleMouseOut = () => {
    setIsHover(false);
  };
  const handleMouseOver1 = () => {
    setIsHover1(true);
  };
  const handleMouseOut1 = () => {
    setIsHover1(false);
  };
  const handleMouseOver2 = () => {
    setIsHover2(true);
  };
  const handleMouseOut2 = () => {
    setIsHover2(false);
  };
  const handleMouseOver3 = () => {
    setIsHover3(true);
  };
  const handleMouseOut3 = () => {
    setIsHover3(false);
  };
  const handleMouseOver4 = () => {
    setIsHover4(true);
  };
  const handleMouseOut4 = () => {
    setIsHover4(false);
  };
  const handleMouseOver5 = () => {
    setIsHover5(true);
  };
  const handleMouseOut5 = () => {
    setIsHover5(false);
  };
  const handleMouseOver6 = () => {
    setIsHover6(true);
  };
  const handleMouseOut6 = () => {
    setIsHover6(false);
  };
  const handleMouseOver7 = () => {
    setIsHover7(true);
  };
  const handleMouseOut7 = () => {
    setIsHover7(false);
  };
  const handleMouseOver8 = () => {
    setIsHover8(true);
  };
  const handleMouseOut8 = () => {
    setIsHover8(false);
  };

  const handleMouseOver9 = () => {
    setIsHover9(true);
  };
  const handleMouseOut9 = () => {
    setIsHover9(false);
  };
  const handleMouseOver10 = () => {
    setIsHover10(true);
  };
  const handleMouseOut10 = () => {
    setIsHover10(false);
  };
  const handleMouseOver11 = () => {
    setIsHover11(true);
  };
  const handleMouseOut11 = () => {
    setIsHover11(false);
  };
  const handleMouseOver12 = () => {
    setIsHover12(true);
  };
  const handleMouseOut12 = () => {
    setIsHover12(false);
  };
  const handleMouseOver13 = () => {
    setIsHover13(true);
  };
  const handleMouseOut13 = () => {
    setIsHover13(false);
  };
  const handleMouseOver14 = () => {
    setIsHover14(true);
  };
  const handleMouseOut14 = () => {
    setIsHover14(false);
  };
  const handleMouseOver15 = () => {
    setIsHover15(true);
  };
  const handleMouseOut15 = () => {
    setIsHover15(false);
  };
  const handleMouseOver16 = () => {
    setIsHover16(true);
  };
  const handleMouseOut16 = () => {
    setIsHover16(false);
  };
  const handleMouseOver17 = () => {
    setIsHover17(true);
  };
  const handleMouseOut17 = () => {
    setIsHover17(false);
  };
  const handleMouseOver18 = () => {
    setIsHover18(true);
  };
  const handleMouseOut18 = () => {
    setIsHover18(false);
  };
  const handleMouseOver19 = () => {
    setIsHover19(true);
  };
  const handleMouseOut19 = () => {
    setIsHover19(false);
  };
  const handleMouseOver20 = () => {
    setIsHover20(true);
  };
  const handleMouseOut20 = () => {
    setIsHover20(false);
  };
  const handleMouseOver21 = () => {
    setIsHover21(true);
  };
  const handleMouseOut21 = () => {
    setIsHover21(false);
  };
  const handleMouseOver22 = () => {
    setIsHover22(true);
  };
  const handleMouseOut22 = () => {
    setIsHover22(false);
  };
  const handleMouseOver23 = () => {
    setIsHover23(true);
  };
  const handleMouseOut23 = () => {
    setIsHover23(false);
  };
  const handleMouseOver24 = () => {
    setIsHover24(true);
  };
  const handleMouseOut24 = () => {
    setIsHover24(false);
  };
  const handleMouseOver25 = () => {
    setIsHover25(true);
  };
  const handleMouseOut25 = () => {
    setIsHover25(false);
  };
  const handleMouseOver26 = () => {
    setIsHover26(true);
  };
  const handleMouseOut26 = () => {
    setIsHover26(false);
  };
  const handleMouseOver27 = () => {
    setIsHover27(true);
  };
  const handleMouseOut27 = () => {
    setIsHover27(false);
  };
  const handleMouseOver28 = () => {
    setIsHover28(true);
  };
  const handleMouseOut28 = () => {
    setIsHover28(false);
  };

  const handleMouseOver29 = () => {
    setIsHover29(true);
  };
  const handleMouseOut29 = () => {
    setIsHover29(false);
  };
  const handleMouseOver30 = () => {
    setIsHover30(true);
  };
  const handleMouseOut30 = () => {
    setIsHover30(false);
  };

  const handleMouseOver31 = () => {
    setIsHover31(true);
  };
  const handleMouseOut31 = () => {
    setIsHover31(false);
  };
  const handleMouseOver32 = () => {
    setIsHover32(true);
  };
  const handleMouseOut32 = () => {
    setIsHover32(false);
  };
  // const handleMouseOver33 = () => {
  //   setIsHover33(true);
  // };
  // const handleMouseOut33 = () => {
  //   setIsHover33(false);
  // };
  // const handleMouseOver34 = () => {
  //   setIsHover34(true);
  // };
  // const handleMouseOut34 = () => {
  //   setIsHover34(false);
  // };
  // const handleMouseOver35 = () => {
  //   setIsHover35(true);
  // };
  // const handleMouseOut35 = () => {
  //   setIsHover35(false);
  // };
  // const handleMouseOver36 = () => {
  //   setIsHover36(true);
  // };
  // const handleMouseOut36 = () => {
  //   setIsHover36(false);
  // };
  // const handleMouseOver37 = () => {
  //   setIsHover37(true);
  // };
  // const handleMouseOut37 = () => {
  //   setIsHover37(false);
  // };
  // const handleMouseOver38 = () => {
  //   setIsHover28(true);
  // };
  // const handleMouseOut38 = () => {
  //   setIsHover28(false);
  // };

  // const handleMouseOver39 = () => {
  //   setIsHover29(true);
  // };
  // const handleMouseOut39 = () => {
  //   setIsHover29(false);
  // };
  // const handleMouseOver40 = () => {
  //   setIsHover40(true);
  // };
  // const handleMouseOut40 = () => {
  //   setIsHover40(false);
  // };
  return (
    <div className="shop-main-block">
      <h3 className="shop-head">Shop</h3>
      <div className="card-row">
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img1.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover && (
              <>
                <img
                  src="assets/shop-img1.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver17}
          onMouseOut={handleMouseOut17}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img18.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover17 && (
              <>
                <img
                  src="assets/shop-img18.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow17(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal17
                  show={modalShow17}
                  onHide={() => setModalShow17(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver1}
          onMouseOut={handleMouseOut1}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img2.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover1 && (
              <>
                <img
                  src="assets/shop-img2.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow1(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal1
                  show={modalShow1}
                  onHide={() => setModalShow1(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver2}
          onMouseOut={handleMouseOut2}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img3.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover2 && (
              <>
                <img
                  src="assets/shop-img3.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow2(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal2
                  show={modalShow2}
                  onHide={() => setModalShow2(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
      </div>

      <div className="card-row">
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver3}
          onMouseOut={handleMouseOut3}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img4.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover3 && (
              <>
                <img
                  src="assets/shop-img4.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow3(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal3
                  show={modalShow3}
                  onHide={() => setModalShow3(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver4}
          onMouseOut={handleMouseOut4}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img5.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover4 && (
              <>
                <img
                  src="assets/shop-img5.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow4(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal4
                  show={modalShow4}
                  onHide={() => setModalShow4(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver27}
          onMouseOut={handleMouseOut27}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img31.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover27 && (
              <>
                <img
                  src="assets/shop-img31.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow27(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal27
                  show={modalShow27}
                  onHide={() => setModalShow27(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
      </div>

      <div className="card-row">
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver6}
          onMouseOut={handleMouseOut6}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img7.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover6 && (
              <>
                <img
                  src="assets/shop-img7.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow6(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal6
                  show={modalShow6}
                  onHide={() => setModalShow6(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver7}
          onMouseOut={handleMouseOut7}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img8.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover7 && (
              <>
                <img
                  src="assets/shop-img8.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow7(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal7
                  show={modalShow7}
                  onHide={() => setModalShow7(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver8}
          onMouseOut={handleMouseOut8}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img9.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover8 && (
              <>
                <img
                  src="assets/shop-img9.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow8(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal8
                  show={modalShow8}
                  onHide={() => setModalShow8(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
      </div>
      <div className="card-row">
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver9}
          onMouseOut={handleMouseOut9}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img10.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover9 && (
              <>
                <img
                  src="assets/shop-img10.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow9(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal9
                  show={modalShow9}
                  onHide={() => setModalShow9(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver10}
          onMouseOut={handleMouseOut10}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img11.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover10 && (
              <>
                <img
                  src="assets/shop-img11.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow10(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal10
                  show={modalShow10}
                  onHide={() => setModalShow10(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>{" "}
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver13}
          onMouseOut={handleMouseOut13}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img14.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover13 && (
              <>
                <img
                  src="assets/shop-img14.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow13(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal13
                  show={modalShow13}
                  onHide={() => setModalShow13(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver11}
          onMouseOut={handleMouseOut11}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img13.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover11 && (
              <>
                <img
                  src="assets/shop-img13.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow11(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal11
                  show={modalShow11}
                  onHide={() => setModalShow11(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}
      </div>
      <div className="card-row">
        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver12}
          onMouseOut={handleMouseOut12}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img12.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover12 && (
              <>
                <img
                  src="assets/shop-img12.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow12(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal12
                  show={modalShow12}
                  onHide={() => setModalShow12(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}

        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver14}
          onMouseOut={handleMouseOut14}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img15.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover14 && (
              <>
                <img
                  src="assets/shop-img15.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow14(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal14
                  show={modalShow14}
                  onHide={() => setModalShow14(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}
      </div>
      <div className="card-row">
        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver15}
          onMouseOut={handleMouseOut15}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img16.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover15 && (
              <>
                <img
                  src="assets/shop-img16.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow15(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal15
                  show={modalShow15}
                  onHide={() => setModalShow15(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}
        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver16}
          onMouseOut={handleMouseOut16}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img17.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover16 && (
              <>
                <img
                  src="assets/shop-img17.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow16(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal16
                  show={modalShow16}
                  onHide={() => setModalShow16(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}
      </div>
      <div className="card-row">
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver18}
          onMouseOut={handleMouseOut18}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img19.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover18 && (
              <>
                <img
                  src="assets/shop-img19.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow18(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal18
                  show={modalShow18}
                  onHide={() => setModalShow18(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver19}
          onMouseOut={handleMouseOut19}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img20.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover19 && (
              <>
                <img
                  src="assets/shop-img20.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow19(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal19
                  show={modalShow19}
                  onHide={() => setModalShow19(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver20}
          onMouseOut={handleMouseOut20}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img21.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover20 && (
              <>
                <img
                  src="assets/shop-img21.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow20(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal20
                  show={modalShow20}
                  onHide={() => setModalShow20(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
      </div>
      <div className="card-row">
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver31}
          onMouseOut={handleMouseOut31}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img35.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover31 && (
              <>
                <img
                  src="assets/shop-img35.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow31(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal31
                  show={modalShow31}
                  onHide={() => setModalShow31(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver22}
          onMouseOut={handleMouseOut22}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img23.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover22 && (
              <>
                <img
                  src="assets/shop-img23.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow22(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal22
                  show={modalShow22}
                  onHide={() => setModalShow22(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver23}
          onMouseOut={handleMouseOut23}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img24.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover23 && (
              <>
                <img
                  src="assets/shop-img24.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow23(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal23
                  show={modalShow23}
                  onHide={() => setModalShow23(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
      </div>
      <div className="card-row">
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver28}
          onMouseOut={handleMouseOut28}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img32.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover28 && (
              <>
                <img
                  src="assets/shop-img32.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow28(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal28
                  show={modalShow28}
                  onHide={() => setModalShow28(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver24}
          onMouseOut={handleMouseOut24}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img25.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover24 && (
              <>
                <img
                  src="assets/shop-img25.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow24(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal24
                  show={modalShow24}
                  onHide={() => setModalShow24(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver25}
          onMouseOut={handleMouseOut25}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img30.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover25 && (
              <>
                <img
                  src="assets/shop-img30.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow25(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal25
                  show={modalShow25}
                  onHide={() => setModalShow25(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
        <div
          className="shop-card-col"
          onMouseOver={handleMouseOver26}
          onMouseOut={handleMouseOut26}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img27.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover26 && (
              <>
                <img
                  src="assets/shop-img27.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow26(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal26
                  show={modalShow26}
                  onHide={() => setModalShow26(false)}
                />
              </>
            )}
          </div>
          {/* <div className="card-texts">
            <p className="card-title-1">I'm a product</p>
            <p className="card-title-1">₹20.00</p>
          </div> */}
        </div>
      </div>

      <div className="card-row">
        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver29}
          onMouseOut={handleMouseOut29}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img33.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover29 && (
              <>
                <img
                  src="assets/shop-img33.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow29(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal29
                  show={modalShow29}
                  onHide={() => setModalShow29(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}
      </div>

      <div className="card-row">
        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver30}
          onMouseOut={handleMouseOut30}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img34.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover30 && (
              <>
                <img
                  src="assets/shop-img34.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow30(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal30
                  show={modalShow30}
                  onHide={() => setModalShow30(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}

        {/* <div
          className="shop-card-col"
          onMouseOver={handleMouseOver32}
          onMouseOut={handleMouseOut32}
        >
          <div className="card-imgs-blk">
            <img
              src="assets/shop-img36.jpeg"
              alt=""
              className="card-shop-imges"
            />
            {isHover32 && (
              <>
                <img
                  src="assets/shop-img36.jpeg"
                  alt=""
                  className="card-hover-imges"
                />
                <Button
                  className="card-hover-btn"
                  onClick={() => setModalShow32(true)}
                >
                  Quick view
                </Button>
                <MyVerticallyCenteredModal32
                  show={modalShow32}
                  onHide={() => setModalShow32(false)}
                />
              </>
            )}
          </div>
          // <div className="card-texts">
          //   <p className="card-title-1">I'm a product</p>
          //   <p className="card-title-1">₹20.00</p>
          // </div>
        </div> */}
      </div>
    </div>
  );
}

export default Products;
