import React from "react";
import "./home.css";

function Home() {
  return (
    <div className="home-page-main">
      <div className="home-img-blk">
        <div className="outside-box-blk">
          <img src="assets/shop-img26.jpeg" alt="" className="home-img1" />
        </div>
        {/* <div className="home-icon-block">
          <BsFillChatTextFill className="home-icon" />
        </div> */}
        <div className="inside-img-blk">
          <h2 className="heading-one">MY FAB COLLECTION</h2>
          <hr className="hr-line" />
          <h5 className="heading-two">HandCrafted Collection</h5>
        </div>
      </div>
    </div>
  );
}

export default Home;
