import React, { useState, useEffect } from "react";
import "./footer.css";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { FaInstagram, FaFacebookF, FaPinterestP } from "react-icons/fa";

const ScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Footer() {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.screenY > 300) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    });
  }, []);

  return (
    <div className="footer-main-block">
      <img src="assets/myfab.png" alt="" className="footerlogo" />
      <MdOutlineKeyboardArrowUp onClick={ScrollTop} className="up-arrow" />

      <p className="top-text" onClick={ScrollTop}>
        Top
      </p>

      <p className="footer-head">faq</p>
      <p className="top-text ">Shopping and Returns</p>
      <p className="top-text ">Terms and Condition</p>
      <div className="footer-social-links">
        <FaFacebookF className="footer-links" />
        <FaPinterestP className="footer-links" />
        <FaInstagram className="footer-links" />
      </div>
      <a className="atomicloopslink" href="https://www.atomicloops.com/">
        {" "}
        <p className="top-text ">© 2022 by Atomicloops.</p>
      </a>
    </div>
  );
}

export default Footer;
