import "./app.css";
import Products from "./pages/products/Products";
import Home from "./pages/home/Home";
import AboutUs from "./pages/aboutus/AboutUs";
import ContactUs from "./pages/contactus/ContactUs";
import Footer from "./componants/footer/Footer";
import { FaBars } from "react-icons/fa";
import { useRef, useState } from "react";

function App() {
  const [toggle, setToggle] = useState(false);

  const home = useRef(null);
  const products = useRef(null);
  const aboutus = useRef(null);
  const contactus = useRef(null);
  const footer = useRef(null);

  const ScrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <nav className="navbar-main ">
        <img src="assets/myfab.png" alt="" className="clogo" />
        <ul
          className={toggle ? "nav-links-mobile" : "nav-links"}
          onClick={() => {
            setToggle(false);
          }}
        >
          <li onClick={() => ScrollToSection(home)} className="scroll-link">
            Home
          </li>
          <li onClick={() => ScrollToSection(products)} className="scroll-link">
            Shop
          </li>
          <li onClick={() => ScrollToSection(aboutus)} className="scroll-link">
            About
          </li>
          <li
            onClick={() => ScrollToSection(contactus)}
            className="scroll-link"
          >
            Contact
          </li>
        </ul>

        <FaBars
          className="hamburger-menu"
          onClick={() => {
            setToggle(!toggle);
          }}
        />
      </nav>

      <div ref={home} className="navber-home">
        <Home />
      </div>
      <div ref={products} className="navber-home">
        <Products />
      </div>
      <div ref={aboutus} className="navber-home">
        <AboutUs />
      </div>
      <div className="parallax-effect"> </div>
      <div ref={contactus} className="navber-home">
        <ContactUs />
      </div>
      <div ref={footer} className="navber-home">
        <Footer />
      </div>
    </>
  );
}

export default App;
